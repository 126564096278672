$lower-gradient-color: lighten(#1B85C2, 33%);

body {
  height: 100%;
  font-weight: 600;
  min-height: 100vh;
  overscroll-behavior: none;
  font-family: "Open Sans", Arial, "Helvetica Neue", sans-serif;
  background-color: #E1F1FF;
  background-image: linear-gradient(#fff, $lower-gradient-color);
}

#app {
  min-height: 320px;
}

.d-grid {
  margin-bottom: 1rem;
}
