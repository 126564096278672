%footerLinks {
  color: #131313;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #131313;
  }
}

footer {
  font-family: sans-serif;
  font-size: 14px;
  position: relative;
  font-weight: 400;


  @include media-breakpoint-up(sm) {
    top: 0;
  }

  a {
    @extend %footerLinks
  }

  @include media-breakpoint-up(sm) {
    &.container {
      max-width: 540px;
    }
  }

  @include media-breakpoint-up(md) {
    &.container {
      max-width: 640px;
    }
  }
}

.footer-links-wrap {
  font-weight: 300;
  margin-bottom: 1rem;
}

.tcpa-wrap {
  font-size: 11px;
  margin-bottom: 2rem;

  a {
    @extend %footerLinks;
  }
}

.disclaimer-text {
  font-size: 11px;
  font-weight: 400;
}
